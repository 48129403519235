<template>
  <div class="position_card">
    <div class="job_card_inner">
      <div class="job_detail" @click="goUrl('job')" style="flex: 1">
        <div class="job_title">
          <div class="job_name">{{ item.postName }}</div>
          <div v-if="item.salType === 1" class="job_salary">
            {{ item.salLow }}-{{ item.salHigh }}K
          </div>
          <div v-else-if="item.salType === 2" class="job_salary">
            {{ userStore.SALARY_TALK }}
          </div>
          <div v-else-if="item.salType === 3" class="job_salary">
            {{ item.salLow }}-{{ item.salHigh }}/天
          </div>
        </div>
        <div class="job_brief">
          <span>{{ item.cityName }}</span> |
          <span>{{ item.workExperienceName }}</span> |
          <span>{{ userStore.getDegree(item.education) }}</span>
        </div>
        <div class="tag_list">
          <div class="tag" :key="ele" v-for="ele in keywords">{{ ele }}</div>
        </div>
      </div>

      <div class="company_wrapper" @click="goUrl('company')">
        <div class="company">
          <div class="com_brief">
            <div class="com_name">
              {{ item.fullName }}
            </div>
            <div class="com_txt">
              <span>{{ item.industryClassificationName }}</span> |
              <span>{{ item.companyScaleName }}</span>
            </div>
          </div>
          <div class="com_photo">
            <img :src="item.logoUrl" />
          </div>
        </div>
        <div class="tag_list" v-if="item.companyWelfareList">
          <div
            class="tag"
            :key="index"
            v-for="(ele, index) in item.companyWelfareList"
          >
            {{ ele.welfareName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useUserStore } from "@/store/user/user";
import { keywordSplit } from "@/util/util";

export default {
  name: "positionCard",
  props: ["item"],
  data() {
    return {
      keywords: [],
    };
  },
  methods: {
    goUrl(act) {
      if (
        act === "company" &&
        this.$parent.showPage &&
        this.$parent.showPage === "collect"
      ) {
        //来自我的收藏
        act = "job";
      }
      if (act === "company") {
        this.$router.push({
          path: "companyDetail",
          query: {
            id: this.item.companyId,
          },
        });
      } else if (act === "job") {
        this.$router.push({
          path: "jobDetail",
          query: {
            id: this.item.companyPostId,
          },
        });
      }
    },
  },
  mounted() {
    if (this.item.keyWords) {
      this.keywords = keywordSplit(this.item.keyWords); //this.item.keyWords.split(",");
    }
  },
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>

<style scoped lang="less">
@import "../assets/css/variable";
.position_card {
  width: 862px;
  height: 164px;
  overflow: hidden;
  background-color: #fff;
  .job_card_inner {
    margin: 25px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .job_detail {
    cursor: pointer;
  }
  .job_title {
    font-size: 18px;
    color: #1a1a1a;
    display: flex;
    align-items: flex-start;
    width: 300px;
    justify-content: space-between;

    .job_salary {
      color: #ed6e2d;
    }
  }
  //.split_line{
  //  border-top:1px solid #f0f3f7;
  //  width:100%;
  //  margin:25px auto;
  //}
  .job_name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1;
  }
  .job_brief {
    font-size: 14px;
    color: #999999;
    margin-top: 15px;
  }
  .company_wrapper {
    cursor: pointer;
    .tag_list {
      justify-content: flex-end;
    }
  }
  .company {
    display: flex;
    justify-content: flex-end;
    .com_brief {
      margin-right: 10px;
      text-align: right;
    }
    .com_name {
      font-size: 14px;
      color: #666666;
    }
    .com_txt {
      font-size: 14px;
      color: #999999;
      margin-top: 3px;
    }
    .com_photo {
      width: 50px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
