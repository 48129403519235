import request from "@/util/request";

export function getMsgCode(data){
    return request({
        url:"/api/user/sms/sendMsg",
        method:"post",
        params:data
    });
}
export function login(data){
    return request({
        url:"/api/user/login",
        method:"post",
        params:data
    })
}
export function getUserInfo(){
    return request({
        url:"/api/user/userInfo",
        method:"post",
        act:"form"
    });
}
export function refreshToken(){
    return request({
        url:"/api/user/refresh/token",
        method:"post",
        token_type:"refresh_uer_token"
    });
}
export function updateAdvantage(data){
    return request({
        url:"/api/user/updateAdvantage",
        method:"post",
        params:data
    })
}
export function updateHobbies(data){
    return request({
        url:"/api/user/updateHobbies",
        method:"post",
        params:data
    })
}
export function updateUser(data){
    return request({
        url:"/api/user/updateUser",
        method:"post",
        params:data
    })
}
export function updateUserCertificate(data){
    return request({
        url:"/api/user/updateUserCertificate",
        method:"post",
        params:data
    })
}
export function updateUserEducationBackground(data){
    return request({
        url:"/api/user/updateUserEducationBackground",
        method:"post",
        params:data
    })
}
export function updateUserProjectExperience(data){
    return request({
        url:"/api/user/updateUserProjectExperience",
        method:"post",
        params:data
    })
}
export function updateUserWorkExperience(data){
    return request({
        url:"/api/user/updateUserWorkExperience",
        method:"post",
        params:data
    })
}
export function updateUserWorkStatus(data){
    return request({
        url:"/api/user/updateUserWorkStatus",
        method:"post",
        params:data
    })
}
export function uploadAvatar(data){
    return request({
        url:"/api/user/uploadAvatar",
        method:"post",
        act:"form",
        params:data
    })
}
export function uploadImage(data){
    return request({
        url:"/api/user/uploadImage",
        method:"post",
        act:"form",
        params:data
    });
}
export function uploadResume(data){
    return request({
        url:"/api/user/uploadResume",
        method:"post",
        act:"form",
        params:data
    })
}
export function getIndustryList(){
    return request({
        url:"/api/user/industryClassificationList",
        method:"post"
    })
}
export function getPositionList(){
    return request({
        url:"/api/user/postClassificationList",
        method:"post"
    })
}
export function getWorkExperienceList(){
    return request({
        url:"/api/user/workExperienceList",
        method:"post"
    })
}
export function getBannerList(data){
    return request({
        url:"/api/user/getBanner",
        method:"post",
        act:"form",
        params:data
    })
}

export function getBannerInfo(data){
    return request({
        url:"/api/user/getBannerInfo",
        method:"post",
        params:data
    })
}

export function getCompany(data){
    return request({
        url:"/api/user/getCompany",
        method:"post",
        params:data
    })
}
export function getCompanyScaleList(){
    return request({
        url:"/api/user/companyScaleList",
        method:"post"
    })
}
export function searchCompanyList(data){
    return request({
        url:"/api/user/searchCompanyList",
        method:"post",
        params:data
    })
}
export function getFooterInfo(){
    return request({
        url:"/api/user/footer",
        method:"post"
    })
}
export function applyJob(data){
    return request({
        url:"/api/user/userApplyPost",
        method:"post",
        params:data
    })
}
export function collectJob(data){
    return request({
        url:"/api/user/userCollect",
        method:"post",
        params:data
    })
}

export function getPostDetail(data){
    return request({
        url:"/api/user/indexCompanyPostInfo",
        method:"post",
        params:data
    })
}

export function indexHotPosition(){
    return request({
        url:"/api/user/indexHotPostClassificationList",
        method:"post",
    });
}

export function indexHotPostList(data){
    return request({
        url:"/api/user/indexHotCompanyPostList",
        method:"post",
        params:data
    })
}

export function indexHotCompany(){
    return request({
        url:"/api/user/indexHotCompany",
        method:"post",
    });
}

export function sameCityJobList(data){
    return request({
        url:"/api/user/sameCityCompanyPostList",
        method:"post",
        params:data
    })
}
export function sameCompanyJobList(data){
    return request({
        url:"/api/user/sameCompanyPostList",
        method:"post",
        params:data
    })
}
export function relatedJobList(data){
    return request({
        url:"/api/user/relatedCompanyPostList",
        method:"post",
        params:data
    })
}

export function myInviteJob(data){
    return request({
        url:"/api/user/companyInviteList",
        method:"post",
        params:data
    })
}
export function indexHotPositionKeywords(){
    return request({
        url:"/api/user/indexHotPostClassification",
        method:"post",
    });
}

export function indexSearch(data){
    return request({
        url:"/api/user/indexSearch",
        method:"post",
        params:data
    });
}

export function inCompanyPostList(data){
    return request({
        url:"/api/user/inCompanyPostList",
        method:"post",
        params:data
    });
}

export function moreCompanyList(data){
    return request({
        url:"/api/user/moreCompanyList",
        method:"post",
        params:data
    })
}

export function updateExpectPost(data){
    return request({
        url:"/api/user/updateExpectPost",
        method:"post",
        params:data
    });
}
export function getTokenByS(data){
    return request({
        url:"/api/user/verifyToken",
        method:"post",
        params:data
    })
}
export function getArticle(data){
    return request({
        url:"/api/user/getArticle",
        method:"post",
        params:data
    })
}
export function getCollection(data){
    return request({
        url:"/api/user/userCollectionCompanyPostList",
        method:"post",
        params:data
    })
}

export function uploadResumeWord(data){
    return request({
        url:"/api/user/uploadResumeWord",
        method:"post",
        act:"form",
        params:data
    });
}
