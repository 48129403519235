<template>
  <div class="cat_box">
    <div class="cat_left">
      <div
          v-for="item in userStore.positionList"
          :key="item.id"
          class="cat_left_item"
          v-show="item.parentId === 0"
          @mouseover="funcShowSubCat(item)">
        {{item.name}} <div class="triangle_point"></div>
      </div>
    </div>
    <div class="cat_right" :class="{show_cat_right:showSubCat}">
      <template v-for="cat in jobCatList" :key="cat.id">
        <div v-if="cat.subCatList.length>0">
          <div class="cat_title">{{cat.name}}</div>
          <div class="cat_content">
            <div class="cat_item"
                 :class="{cat_item_cur:subcat.id === selectedCat.id}"
                 :key="subcat.id"
                 v-for="subcat in cat.subCatList"
                 @click="selectCat(subcat)">{{subcat.name}}</div>
          </div>
        </div>
      </template>

      <div v-if="firstLevelList.length > 0">
<!--        <div class="cat_title"></div>-->
        <div class="cat_content" style="padding:15px 0;">
          <div class="cat_item"
               :class="{cat_item_cur:subcat.id === selectedCat.id}"
               :key="subcat.id"
               v-for="subcat in firstLevelList"
               @click="selectCat(subcat)">{{subcat.name}}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import {indexHotPosition} from "@/api/user/user";

import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";

export default {
  name: "catBox",
  data(){
    return {
      showSubCat:false,
      jobCatList:[], //二级分类
      firstLevelList:[],//二级没有子分类
      selectedCat:{
        id:0,
        name:""
      }
    }
  },
  methods:{
    selectCat(cat){
      this.selectedCat = cat;
      this.$emit("sub_event",{
        act:"select_cat",
        info:cat
      });
    },
    // funcShowSubCat(firstCat){
    //   let parentId = firstCat.id;
    //   this.showSubCat=true;
    //   let industryList = JSON.parse(JSON.stringify(this.userStore.industryList));
    //   this.jobCatList = [];
    //   this.firstLevelList = [];
    //
    //   for(let i in industryList){
    //     if(industryList[i].parentId === parentId){
    //       let subCatList = this.getSubCat(industryList[i].id);
    //       if(subCatList.length >0 ){
    //         this.jobCatList.push({
    //           name:industryList[i].name,
    //           id:industryList[i].id,
    //           subCatList:subCatList
    //         });
    //       }else{
    //         this.firstLevelList.push({
    //           name:industryList[i].name,
    //           id:industryList[i].id
    //         });
    //       }
    //     }
    //   }
    // },
    // getSubCat(parentId){
    //   let list=[];
    //   let industryList = JSON.parse(JSON.stringify(this.userStore.industryList));
    //   for(let i in industryList){
    //     if(industryList[i].parentId === parentId){
    //       list.push({
    //         name:industryList[i].name,
    //         id:industryList[i].id,
    //         subCatList:[]
    //       });
    //     }
    //   }
    //   return list;
    // },
    funcShowSubCat(firstCat){
      let parentId = firstCat.id;
      this.showSubCat=true;
      let positionList = JSON.parse(JSON.stringify(this.userStore.positionList));
      this.jobCatList = [];
      this.firstLevelList = [];

      for(let i in positionList){
        if(positionList[i].parentId === parentId){
          let subCatList = this.getSubCat(positionList[i].id);
          if(subCatList.length >0 ){
            this.jobCatList.push({
              name:positionList[i].name,
              id:positionList[i].id,
              subCatList:subCatList
            });
          }else{
            this.firstLevelList.push({
              name:positionList[i].name,
              id:positionList[i].id
            });
          }
        }
      }
    },
    getSubCat(parentId){
      let list=[];
      let positionList = JSON.parse(JSON.stringify(this.userStore.positionList));
      for(let i in positionList){
        if(positionList[i].parentId === parentId){
          list.push({
            name:positionList[i].name,
            id:positionList[i].id,
            subCatList:[]
          });
        }
      }
      return list;
    },
  },
  mounted(){
    // this.funcShowSubCat(this.userStore.industryList[0]);
    if(this.userStore.positionList.length > 0){
      this.funcShowSubCat(this.userStore.positionList[0]);
    }

  },
  watch:{
    "userStore.positionList.length":{
      handler(v){
        console.log("position list");
        this.funcShowSubCat(this.userStore.positionList[0]);
      }
    }
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style lang="less">
@import url(../assets/css/variable);
  .cat_box{
    position:relative;
    display:flex;
    min-height:300px;
    max-height:600px;
    .cat_left{
      min-width:140px;
      border-radius: 10px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 3px 3px 0 #dcdcdc;
      .cat_left_item{
        position:relative;
        height:40px;
        padding:0 25px;
        justify-content: center;
        line-height:40px;
        font-size: 14px;
        color: #1a1a1a;
        display:flex;
        align-items: center;
        cursor:pointer;
        .triangle_point{
          width:8px;height:9px;
          margin-left:10px;
          position:absolute;
          right:15px;
          background: url(../assets/img/triangle_gray_right.png) no-repeat center center / auto 100%;
        }
      }
      .cat_left_item:hover,
      .cat_left_item_cur{
        background-color: #f5f5f5;
        color:@blue_color;
        .triangle_point{
          width:8px;height:9px;
         background: url(../assets/img/triangle_blue_right.png) no-repeat center / auto 100%;
          transform: initial;
        }
      }
    }
    .cat_right{
      //position:absolute;
      box-shadow: 3px 3px 0 #dcdcdc;
      transition: width .2s;
      top:0;
      margin-left:20px;
      width:0;
      border-radius: 10px;
      background-color:#fff;
      height:100%;
      overflow-x: hidden;
      overflow-y:auto;
      .cat_title{
        margin-left:15px;
        font-size: 14px;
        margin-top:10px;
        line-height: 35px;
        height:35px;
        color: #1a1a1a;
        font-weight: bold;
      }
      .cat_content{
        display:flex;
        flex-wrap: wrap;
        .cat_item{
          cursor:pointer;
          height:25px;line-height:25px;font-size:14px;
          margin-left:15px;color:#999999;
        }
        .cat_item_cur{
          color:@blue_color;
        }
      }
    }
    .show_cat_right{
      width:520px
    }
  }
</style>
