import request from "@/util/request";

export function getProvinceList(){
    return request({
        url:"/api/user/provinceList",
        method:"post"
    })
}
export function getCityList(data){
    return request({
        url:"/api/user/cityList",
        method:"post",
        params:data,
        act:"form"
    })
}

export function getAreaList(data){
    return request({
        url:"/api/user/areaList",
        method:"post",
        params:data,
        act:"form"
    })
}
