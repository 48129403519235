import { defineStore } from 'pinia';
import {login, getMsgCode, getUserInfo, getIndustryList, getPositionList, getBannerList} from "@/api/user/user";
import { ElMessage } from "element-plus";
import defaultPhoto from "@/assets/img/test_photo.svg";
import  CONSTANT from "@/api/user/constant";
export const useUserStore = defineStore( {
    id:"user",
    state: () => ({
        SALARY_TALK:CONSTANT.SALARY_TALK,
        userInfo: {

        },
        jobStatus:[
            {
                label:"离职-随时到岗",
                value:"离职-随时到岗",
                id:1
            },{
                label:"在职-寻找机会",
                value:"在职-寻找机会",
                id:2
            },{
                label:"在职-暂不考虑",
                value:"在职-暂不考虑",
                id:3
            }
        ],
        industryList:[],
        bannerList:[],
        loadIndustryListComplete:false,
        positionList:[],
        sexList:[
            {
                label:"男",
                value:"男"
            },{
                label:"女",
                value:"女"
            }
        ],
        xuezhi:[
            {
                label:"全日制",
                value:1
            }, {
                label:"非全日制",
                value:2
            },
        ],
        degree:[{
            label:"学历不限",
            value:0
        },{
            label:"小学",
            value:1
        },{
            label:"初中",
            value:2
        },{
            label:"中专/高中",
            value:3
        },{
            label:"专科",
            value:4
        },{
            label:"本科",
            value:5
        },{
            label:"硕士研究生",
            value:6
        },{
            label:"博士研究生",
            value:7
        }]
    }),
    getters: {
        isLogin: (state) => state && state.userInfo && Object.keys(state.userInfo).length>0,
        gender(state){
            if(!state.userInfo.user.sex || isNaN(state.userInfo.user.sex)){
                return "";
            }else{
                return state.sexList[state.userInfo.user.sex].label;
            }
        },
        curWorkStatus(state){
            if(!state.userInfo.user.workStatus || isNaN(state.userInfo.user.workStatus)){
                return "";
            }else{
                return state.jobStatus[state.userInfo.user.workStatus -1].label;
            }
        }
    },
    actions: {
        getMsgCode(data){
            return getMsgCode(data);
        },
        login(data){
           return login(data);
        },
        logout(){
            localStorage.removeItem("JOB_USER_TOKEN");
            localStorage.removeItem("JOB_USER_REFRESH_TOKEN");
            this.userInfo={};
        },
        getDegree(v){
            let degree = "";
            for(let i in this.degree){
                if(this.degree[i].value === v){
                    degree = this.degree[i].label;
                    break;
                }
            }
            return degree;
        },
        getXueZhi(v){
            let xz = "";
            for(let i in this.xuezhi){
                if(this.xuezhi[i].value === v){
                    xz = this.xuezhi[i].label
                }
            }
            return xz;
        },
        getJobStatus(v){
            let s = "";
            for(let i in this.jobStatus){
                if(this.jobStatus[i].id === v){
                    s = this.jobStatus[i].label;
                }
            }
            return s;
        },
        getIndustryList(){
            getIndustryList().then(res => {
                if(res.code === 0){
                    this.industryList = res.data;
                    this.loadIndustryListComplete = true;
                }
            });
        },
        getBannerList(){
            return new Promise((resolve) => {
                if(this.bannerList.length === 0){
                    getBannerList({types:"1,2,3,4,5,6,7"}).then(res => {
                        if(res.code === 0){
                            this.bannerList = res.data;
                            resolve(this.bannerList);
                        }
                    });
                }else{
                    resolve(this.bannerList);
                }
            })
        },
        getPositionList(){
            return getPositionList().then(res => {
                if(res.code === 0){
                    this.positionList = res.data;
                }
            });
        },
        getUserInfo(){
            getUserInfo().then(res => {
                if(res.code === 0){
                    if(!res.data.user.avatarUrl){
                        res.data.user.avatarUrl = defaultPhoto;
                    }
                    // if(!res.data.user.realName){
                    //     res.data.user.realName = "未定义";
                    // }
                    this.userInfo = res.data;
                }else if(res.code === 10){
                    //过期
                    // refreshToken().then(res => {
                        // console.log("refresh_token",res);
                        // if(res.code === 0){
                        //     localStorage.setItem("JOB_USER_TOKEN",res.data.token);
                        //     localStorage.setItem("JOB_USER_REFRESH_TOKEN",res.data.refreshToken);
                        //     this.getUserInfo();
                        // }else{
                        //     ElMessage.error(res.msg);
                        //     localStorage.removeItem("JOB_USER_TOKEN");
                        //     localStorage.removeItem("JOB_USER_REFRESH_TOKEN");
                        // }
                    // });
                }else{
                    ElMessage.error(res.msg);
                    this.userInfo = {};
                    localStorage.removeItem("JOB_USER_TOKEN");
                    localStorage.removeItem("JOB_USER_REFRESH_TOKEN");
                }
            });
        },
        setUserInfo(userInfo){
            this.userInfo = userInfo;
        }
    },
})
