<template>
<div class="side_banner">
  <div class="a_wrapper" @click="goBanner('top')" v-if="Object.keys(topBanner).length > 0">
    <img :src="topBanner.imageUrl"/>
  </div>
  <div class="a_wrapper" @click="goBanner('middle')" v-if="Object.keys(middleBanner).length > 0">
    <img :src="middleBanner.imageUrl"/>
  </div>
  <div class="a_wrapper" @click="goBanner('bottom')" v-if="Object.keys(bottomBanner).length > 0">
    <img :src="bottomBanner.imageUrl"/>
  </div>
</div>
</template>

<script>
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";

export default {
  name: "rightAd",
  data(){
    return {
      topBanner:{

      },
      bottomBanner:{

      },
      middleBanner:{

      }
    }
  },
  methods:{
    goBanner(act){
      if(act === "top"){
        if(this.topBanner.jumpLink !== ""){
          window.open(this.topBanner.jumpLink);
        }
      }else if(act === "bottom"){
        if(this.bottomBanner.jumpLink !== ""){
          window.open(this.bottomBanner.jumpLink);
        }
      }else if(act === "middle"){
        if(this.middleBanner.jumpLink !== ""){
          window.open(this.middleBanner.jumpLink);
        }
      }
    }
  },
  mounted(){
    this.userStore.getBannerList().then(list => {
      for(let i in list){
        let type = list[i].type;
        if(type === 5){
          this.topBanner = list[i];
        }else if(type === 7){
          this.bottomBanner = list[i];
        }else if(type === 6){
          this.middleBanner = list[i];
        }
      }
    });
  },
  computed:{
    ...mapStores(useUserStore)
  }
}
</script>

<style lang="less" scoped>
  .a_wrapper{
    margin-bottom:10px;
    width:317px;
    max-width: 100%;
    height:167px;
    cursor:pointer;
    img{
      width:100%;
      height:100%;
    }
  }
</style>
