<template>
  <div class="invite_job">
    <div class="job_title" @click="goUrl('job')">
      {{item.postName}}
      <div v-if="item.salType === 1" class="job_salary">{{item.salLow}}K-{{item.salHigh}}K</div>
      <div v-else-if="item.salType === 2" class="job_salary">{{userStore.SALARY_TALK}}</div>
      <div v-else-if="item.salType === 3" class="job_salary">{{item.salLow}}-{{item.salHigh}}/天</div>
    </div>
    <div class="company" @click="goUrl('company')">
      <div class="info">
        <img :src="item.logoUrl"/>{{item.shortName}}
      </div>
      <div class="job_brief">
        <span>{{item.cityName}}</span>|<span>{{item.workExperienceName}}</span>|<span>{{userStore.getDegree(item.education)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";

export default {
  name: "inviteJob",
  props:{
    item:{
      type:Object,
      default(){
        return {

        }
      }
    }
  },
  methods:{
    goUrl(act){
      if(act === "company"){
        this.$router.push({
          path:"/companyDetail",
          query:{
            id:this.item.companyId
          }
        });
      }else if(act === "job"){
        this.$router.push({
          path:"/jobDetail",
          query:{
            id:this.item.companyPostId
          }
        });
      }
    }
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style lang="less" scoped>
  .invite_job{
    width:265px;
    .job_title{
      font-size: 18px;
      color: #1a1a1a;
      display: flex;
      justify-content: space-between;
      cursor:pointer;
      .job_salary{
        color:#ed6e2d;
      }
    }
    .company{
      display:flex;
      justify-content: space-between;
      align-items: center;
      margin-top:13px;
      cursor:pointer;
      .info{
        font-size: 14px;
        color: #999999;
        display:flex;
        align-items: center;
        img{
          width:23px;
          height:auto;
          margin-right:5px;
        }
      }
      .job_brief{
        font-size: 12px;
        color: #999999;
      }
    }
  }
</style>
