<template>
  <div class="page_job">
    <div class="content">
      <div
        class="search_bar"
        :class="{ search_focus: searchFocus }"
        @click.stop="focusSearch"
      >
        <!--        <el-select-->
        <!--            v-model="place"-->
        <!--            @click="showCatPanel=false">-->
        <!--          <el-option-->
        <!--              v-for="item in options"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--          />-->
        <!--        </el-select>-->
        <el-cascader
          placeholder="选择地区"
          style="width: 100px; margin-left: 22px"
          :show-all-levels="false"
          @change="cityChange"
          :props="areaProp"
          v-model="cityCode"
        ></el-cascader>

        <div class="btn_show_type" @click="funcShowCatPanel">
          {{ curJobCat.name }} &nbsp;<img
            class="triangle"
            :class="{ triangle_up: showCatPanel }"
            src="../assets/img/icon_triangle.png"
          />
        </div>
        <el-input
          v-model="keyword"
          placeholder="搜索职位"
          @focus="(searchFocus = true), (showCatPanel = false)"
          style="
            flex: 1;
            text-align: left;
            border: none;
            background-color: transparent;
          "
        />
        <el-button class="search_btn" @click="keywordSure">搜索</el-button>
        <div
          style="position: absolute; left: 10px; top: 70px; z-index: 1"
          v-show="showCatPanel"
        >
          <cat-box @sub_event="subEvent"></cat-box>
        </div>
      </div>
      <hot-search @sub_event="subEvent"></hot-search>

      <div class="filter_area">
        <div class="filter_con">
          <div class="filter_title">工作经验</div>
          <div
            class="filter_item"
            :class="{ filter_item_cur: param.workExperienceId === item.id }"
            :key="item.id"
            @click="param.workExperienceId = item.id"
            v-for="item in workExperienceList"
          >
            {{ item.description }}
          </div>
        </div>
        <div class="filter_con">
          <div class="filter_title">学历要求</div>
          <div
            class="filter_item"
            :class="{ filter_item_cur: param.education === item.value }"
            @click="param.education = item.value"
            v-for="item in this.userStore.degree"
            :key="item.value"
          >
            {{ item.label }}
          </div>
        </div>
      </div>

      <div class="bottom_content">
        <div class="job_list">
          <position-card
            v-for="item in jobList"
            :item="item"
            :key="item.companyPostId"
          ></position-card>

          <el-pagination
            v-model:current-page="param.pageNum"
            @current-page="pageChange"
            :page-size="param.pageSize"
            background
            layout="prev, pager, next"
            :total="total"
          />
        </div>
        <div class="content_right">
          <div
            class="invite_list"
            v-if="userStore.isLogin && myInviteJobList.length > 0"
          >
            <div class="invite_title">您收到的邀请</div>
            <invite-job
              v-for="item in myInviteJobList"
              :item="item"
              :key="item.companyPostId"
            ></invite-job>
          </div>
          <right-ad style="margin-top: 10px"></right-ad>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CatBox from "@/components/catBox";
import PositionCard from "@/components/positionCard";
import InviteJob from "@/components/inviteJob";
import RightAd from "@/components/rightAd";
import {
  myInviteJob,
  indexSearch,
  getWorkExperienceList,
} from "@/api/user/user";
import { mapStores } from "pinia";
import { useUserStore } from "@/store/user/user";
import { getCityList, getProvinceList } from "@/api/user/area";
import HotSearch from "@/components/hotSearch";
import { jsCopy } from "@/util/util";
export default {
  name: "job",
  data() {
    return {
      myInviteJobList: [],
      curJobCat: {
        id: 0,
        name: "职业类型",
      },
      workExperienceList: [],
      keyword: "",
      param: {
        cityCode: "",
        education: null,
        pageNum: 1,
        pageSize: 10,
        postClassificationId: null,
        query: "",
        salHigh: null,
        salLow: null,
        workExperienceId: null,
      },
      searchFocus: false,
      showCatPanel: false,
      cityCode: [],
      areaProp: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          let nodes = [];
          if (level === 0) {
            getProvinceList().then((res) => {
              res.data.forEach((v) => {
                nodes.push({
                  label: v.provinceName,
                  value: v.provinceCode,
                  leaf: false,
                });
              });
              resolve(nodes);
            });
          } else if (level === 1) {
            getCityList({
              provinceCode: node.data.value,
            }).then((res) => {
              res.data.forEach((v) => {
                nodes.push({
                  label: v.cityName,
                  value: v.cityCode,
                  leaf: true,
                });
              });
              resolve(nodes);
            });
          }
          // else if(level === 2){
          //   getAreaList({
          //     cityCode:node.data.value
          //   }).then(res => {
          //     res.data.forEach(v => {
          //       nodes.push({
          //         label:v.areaName,
          //         value:v.areaCode,
          //         leaf:true
          //       });
          //     });
          //     resolve(nodes);
          //   });
          // }
        },
      },
      jobList: [],
      total: 0,
    };
  },

  watch: {
    param: {
      deep: true,
      handler(newValue, oldValue) {
        console.log(newValue, oldValue)
        this.search();
      },
    },
  },
  mounted() {
    if (this.userStore.isLogin) {
      myInviteJob({
        pageNum: 1,
        pageSize: 12,
      }).then((res) => {
        if (res.code === 0) {
          this.myInviteJobList = res.data.rows;
        }
      });
    }

    if (this.$route.query.pcid !== undefined) {
      this.param.postClassificationId = this.$route.query.pcid;
      for (let i in this.userStore.positionList) {
        if (
          this.userStore.positionList[i].id === parseInt(this.$route.query.pcid)
        ) {
          this.curJobCat = jsCopy(this.userStore.positionList[i]);
        }
      }
    }
    if (this.$route.query.keyword !== undefined) {
      this.keyword = this.param.query = this.$route.query.keyword;
    } else {
      this.search();
    }
    getWorkExperienceList().then((res) => {
      if (res.code === 0) {
        this.workExperienceList = res.data;
      }
    });
  },
  components: {
    HotSearch,
    RightAd,
    InviteJob,
    PositionCard,
    CatBox,
  },
  computed: {
    ...mapStores(useUserStore),
  },
  methods: {
    subEvent(param) {
      if (param.act === "select_cat") {
        this.curJobCat = param.info;
        this.param.postClassificationId = param.info.id;
      } else if (param.act === "set_hot_search") {
        this.keyword = this.param.query = param.keyword;
      }
    },
    cityChange() {
      // console.log(this.cityCode);
      this.param.cityCode =
        this.cityCode.length > 1 ? this.cityCode[this.cityCode.length - 1] : "";
    },
    keywordSure() {
      this.param.query = this.keyword;
      // this.search()
    },
    pageChange(v) {
      this.param.pageNum = v;
    },
    focusSearch() {
      let that = this;
      this.searchFocus = true;
      let blur = function () {
        that.searchFocus = false;
        document.removeEventListener("click", blur);
      };
      document.addEventListener("click", blur);
    },
    funcShowCatPanel() {
      this.showCatPanel = !this.showCatPanel;
      if (this.showCatPanel) {
        let that = this;
        let blur = function () {
          that.showCatPanel = false;
          document.removeEventListener("click", blur);
        };
        document.addEventListener("click", blur);
      }
    },
    search() {
      indexSearch(this.param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.jobList = res.data.rows;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style lang="less">
@import "../assets/css/variable";
.page_job {
  .invite_list {
    background-color: #fff;
    padding-top: 10px;
    overflow: hidden;
    .invite_title {
      font-size: 20px;
      line-height: 60px;
      height: 60px;
      color: @blue_color;
      text-align: center;
      margin-bottom: 10px;
    }
    .invite_job {
      margin-left: 25px;
      margin-bottom: 30px;
    }
  }
  .el-pagination {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
  .bottom_content {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .job_list {
      width: 862px;
      .position_card {
        margin-bottom: 10px;
      }
    }
    .content_right {
      width: 312px;
    }
  }
  .hot_search {
    font-size: 14px;
    color: #999999;
    width: 1020px;
    margin: 0;
    text-indent: 37px;
    span {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .search_bar {
    box-sizing: border-box;
    border: 1px solid #fff;
    position: relative;
    background-color: #fff;
    width: 1020px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    margin: 50px 0 10px;
    .search_btn {
      width: 80px;
      height: 40px;
      border-radius: 20px;
      background-color: @blue_color;
      margin-right: 8px;
      color: #fff;
    }
    .btn_show_type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      cursor: pointer;
      color: #1a1a1a;
    }
    .el-select {
      width: 120px;

      .el-input__inner {
        border: none;
        background-color: transparent;
        text-align: center;
        font-size: 16px;
        color: #1a1a1a;
      }
      //.el-icon-arrow-up:before{
      //  content:" ";
      //  display:block;
      //  width: 9px;
      //  height: 5px;
      //  background:url(../assets/img/icon_triangle.png) no-repeat center center / 100% auto;
      //  transform: rotate(180deg);
      //}
      .el-input .el-select__caret {
        //display: flex;
        //justify-content: center;
        //align-items: center;
        display: block;
        width: 9px;
        height: 5px;
        background: url(../assets/img/icon_triangle.png) no-repeat center center /
          100% auto;
        svg {
          display: none;
        }
      }
      .el-input .is-reverse {
        transform: rotate(180deg);
      }
    }
  }
  .search_focus {
    border: 1px solid @blue_color;
  }

  .filter_area {
    background-color: #fff;
    padding: 35px 25px 0;
    overflow: hidden;
    margin-top: 20px;
    .filter_con {
      display: flex;
      margin-bottom: 30px;
      .filter_title {
        font-size: 14px;
        color: #1a1a1a;
        font-weight: bold;
        margin-right: 35px;
      }
      .filter_item {
        font-size: 14px;
        margin-right: 25px;
        cursor: pointer;
        color: #666666;
      }
      .filter_item:hover,
      .filter_item_cur {
        color: @blue_color;
      }
    }
  }
}
</style>
