<template>
  <div class="hot_search">
    热搜：<span v-for="item in hotPositionKeywords" :key="item.id" @click="hotKeywordClick(item)">{{item.name}}</span>
  </div>
</template>

<script>
import {indexHotPositionKeywords} from "@/api/user/user";
export default {
  name: "hotSearch",
  data(){
    return {
      hotPositionKeywords:[]
    }
  },
  methods:{
    hotKeywordClick(item){
      // this.keyword = item.name;
      this.$emit("sub_event",{act:"set_hot_search",keyword:item.name});
    },
  },
  mounted(){
    indexHotPositionKeywords().then(res => {
      if(res.code === 0){
        this.hotPositionKeywords = res.data;
      }
    });
  }
}
</script>

<style scoped>

</style>
